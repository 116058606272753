<template>
  <v-dialog
    :color="$app.opts.modalHeaderColor"
    :value="true"
    @keydown.esc="dismiss"
    @input="(v) => v || dismiss()"
    :max-width="$app.opts.modalSizeDefault"
    persistent
    scrollable
  >
    <contact-card :dismiss="dismiss" />
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { VLoading } from '@argon/app/components'
import { Modal } from '@argon/app/mixins'
import { ContactCard } from '../components'

export default {
  name: 'ModalContact',
  mixins: [Modal],
  components: {
    ContactCard
  },
  data: () => ({
    returnPath: { name: 'me' }
  })
}
</script>
